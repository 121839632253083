import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { useEffect } from "react";
import { useState } from "react";
import { Alert, AlertTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import sound from './sound.mp3';
import { io } from "socket.io-client";


const socket = io.connect("https://dotrestros.onrender.com"); //backend_link

const Superadminpanel = () => {

    const [orderList, setOrderList] = useState([]);
    const [orderSummary, setOrderSummary] = useState({});
    const [renderWindow, setRenderWindow] = useState(<></>);
    const [open, setOpen] = useState(false);
    const [activate, setActivate] = useState(false);
    const [audio, setAudio] = useState(new Audio(sound));
    const [fetched, setFetched] = useState(false);
    const [orderCount, setOrderCount] = useState({declined:0,accepted:0,cancelled:0,total:0});

    const token = localStorage.getItem('token');

    const orderGet = async () => {
        try {
            const data = await fetch(`${process.env.REACT_APP_BACKEND_URI}/orders`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    'Authorization': `BEARER:${token}`
                },
            });
            const orders = await data.json();
            setOrderList(orders);
        } catch (error) {
            console.log(error);
        }
    }

    const handleClickOpen = () => {
        setOpen(true);
        audio.play();
    };

    const handleClose = () => {
        setOpen(false);
        audio.pause();
        setAudio(new Audio(sound));
        setFetched(false);
    };

    useEffect(() => {
        orderGet();
    }, []);

    socket.on('newOrder', async (data) => {
        await orderGet();
        console.log("this is the data ", data);
        setFetched(true);
        socket.off();
    });

    socket.on('cancelOrder', async (data) => {
        await orderGet();
        socket.off();
    });

    useEffect(() => {
        if (fetched && !open) return handleClickOpen();
        return () => { };
    }, [fetched]);

    useEffect(() => {
        console.log("Debug Orderlist: ", orderList);
        const newOrder = orderList[orderList.length - 1];
        console.log('new order debug: ', newOrder);
        if (newOrder) {
            setOrderSummary(newOrder);
        }
        let dcount = 0,acount=0,ccount=0;
        const elements = orderList.map((order) => {
            if(order.paid){
            if(order.declined){
                dcount++;
            }
            else if(order.cancelled){
                ccount++;
            }
            else{
                acount++;
            }
            const items = order.orderDetails.map((a) => {
                return (<>
                    <Grid sx={{ width: '100%', textAlign: 'center', padding: '0px 15px' }}>{a.qty} {a['name']}</Grid>
                </>);
            });
            let orderRender = (<Alert severity="success" sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                <AlertTitle sx={{ fontSize: '17px', marginBottom: '0px' }}>Accepted</AlertTitle>
            </Alert>);
            if (order.cancelled) {
                orderRender = (<Alert severity="warning" sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <AlertTitle sx={{ fontSize: '17px', marginBottom: '0px' }}>Cancelled</AlertTitle>
                </Alert>);
            }
            if (order.declined) {
                orderRender = (<Alert severity="error" sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
                    <AlertTitle sx={{ fontSize: '17px', marginBottom: '0px' }}>Declined</AlertTitle>
                </Alert>);
            }
            console.log("debug value ", order.declined);
            return (<>
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0px', borderBottom: '1px solid black' }}>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.restaurant.name}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.customerDetails.bookingName}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.customerDetails.phoneNumber}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.bookingDetails.date}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.bookingDetails.time}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.bookingDetails.person}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>{order.bookingDetails.advance}</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center' }}>
                        {orderRender}
                    </Grid>
                    <Grid sx={{ width: '20%' }}>
                        <Grid sx={{ width: '100%', textAlign: 'center', padding: '0px 15px' }}>Instruction-{order.bookingDetails.instruction} Combo-{order.bookingDetails.combo}</Grid>
                        {items}
                    </Grid>
                </Grid>
            </>);
        }});
        setOrderCount({declined:dcount,accepted:acount,cancelled:ccount,total:acount+dcount+ccount});
        setRenderWindow(elements);
    }, [orderList]);

    console.log("Window debug: ", orderSummary);

    return (
        <>
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 60px' }}>
                <Grid sx={{ width: '8%' }}>
                    <img src="/images/LOGO.webp" alt="" style={{ width: '100%', height: 'auto' }} />
                </Grid>
                <Grid sx={{ textAlign: 'right' }}>
                    <Typography sx={{ fontSize: '25px', fontFamily: 'sans-serif', color: '#2a88df', fontWeight: 'bold', textDecoration: 'underline' }}>DoT Restros</Typography>
                    {!activate ? <Button onClick={() => {
                        setActivate(true);
                    }} sx={{ fontSize: '17px', fontWeight: '400', color: 'red' }}>Click to Activate</Button>
                        : <Button sx={{ fontSize: '17px', fontWeight: '400', color: 'green' }}>Activated</Button>}
                </Grid>
                {open ? <Dialog
                    open={open}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {`New Order to ${orderSummary.restaurant.name}`}
                    </DialogTitle>
                    <DialogContent>
                        <b>Name -</b> {orderSummary.customerDetails.bookingName} <br />
                        <b>Phone -</b> {orderSummary.customerDetails.phoneNumber} <br />
                        <b>Person -</b> {orderSummary.bookingDetails.person} <br />
                        <b>Booking Date -</b> {orderSummary.bookingDetails.date} <br />
                        <b>Booking Time -</b> {orderSummary.bookingDetails.time}
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} autoFocus sx={{ fontWeight: 'bold' }}>
                            Ok
                        </Button>
                    </DialogActions>
                </Dialog> : <></>
                }
            </Grid >
            <Grid sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '10px 60px', marginTop: '30px' }}>
                <Grid sx={{ width: '22%', height: '100px', backgroundColor: '#257ccc', borderRadius: '5px' }}>
                    <Typography sx={{ color: 'white', margin: '10px 15px', fontSize: '20px', fontFamily: 'sans-serif', fontWeight: '100' }}>
                        Total Orders
                    </Typography>
                    <Grid sx={{ margin: '-5px 0px 0px 35px', color: 'white', fontSize: '30px', fontWeight: 'bold' }}>{orderCount.total}</Grid>
                </Grid>
                <Grid sx={{ width: '22%', height: '100px', backgroundColor: '#257ccc', borderRadius: '5px' }}>
                    <Typography sx={{ color: 'white', margin: '10px 15px', fontSize: '20px', fontFamily: 'sans-serif', fontWeight: '100' }}>
                        Accepted Orders
                    </Typography>
                    <Grid sx={{ margin: '-5px 0px 0px 35px', color: 'white', fontSize: '30px', fontWeight: 'bold' }}>{orderCount.accepted}</Grid>
                </Grid>
                <Grid sx={{ width: '22%', height: '100px', backgroundColor: '#257ccc', borderRadius: '5px' }}>
                    <Typography sx={{ color: 'white', margin: '10px 15px', fontSize: '20px', fontFamily: 'sans-serif', fontWeight: '100' }}>
                        Declined Orders
                    </Typography>
                    <Grid sx={{ margin: '-5px 0px 0px 35px', color: 'white', fontSize: '30px', fontWeight: 'bold' }}>{orderCount.declined}</Grid>
                </Grid>
                <Grid sx={{ width: '22%', height: '100px', backgroundColor: '#257ccc', borderRadius: '5px' }}>
                    <Typography sx={{ color: 'white', margin: '10px 15px', fontSize: '20px', fontFamily: 'sans-serif', fontWeight: '100' }}>
                        Cancelled Orders
                    </Typography>
                    <Grid sx={{ margin: '-5px 0px 0px 35px', color: 'white', fontSize: '30px', fontWeight: 'bold' }}>{orderCount.cancelled}</Grid>
                </Grid>
            </Grid>
            <Grid sx={{ marginTop: '30px', padding: '10px 60px' }}>
                <Typography sx={{ backgroundColor: '#257ccc', color: 'white', fontWeight: '400', fontSize: '20px', paddingLeft: '15px', borderRadius: '5px', height: '40px', display: 'flex', alignItems: 'center' }}>Orders List</Typography>
                <Grid sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '5px 0px', borderBottom: '1px solid black' }}>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Restaurant</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Name</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Contact</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Date</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Time</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Adults</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Advance</Grid>
                    <Grid sx={{ width: '10%', textAlign: 'center', fontWeight: 'bold' }}>Status</Grid>
                    <Grid sx={{ width: '20%', textAlign: 'center', fontWeight: 'bold' }}>Order</Grid>
                </Grid>
                {renderWindow}
            </Grid>
        </>
    )
}

export default Superadminpanel;